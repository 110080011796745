import * as React from "react"
import { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"

const WelcomeMessage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const SetOpen = () => {
    setIsOpen(!isOpen)
  }
  return (
    <section className="welcomemessage-section">
      <div className="welcomemessage-content">
        <div className="welcomemessage-grid">
          <div className="welcomemessage-document">
            <p className="welcomemessage-title">Mensaje de Bienvenida</p>
            <div className={`welcomemessage-description${isOpen ? "-open" : ""}`} >
              <p className="welcomemessage-text">
                Bienvenido al Centro Hidalguense de Estudios Superiores, casa académica que es testigo de la pasión, el sueño y la constancia de nuestros alumnos, catedráticos, personal administrativo y de apoyo, que viven como legado la consigna de que la educación es la mejor inversión, para los que en ella buscan desarrollarse.
              </p>
              <p className="welcomemessage-text">
                La sociedad actual requiere que las instituciones de educación formen hombres y mujeres con un alto sentido de responsabilidad social que velen por el bien común; interesados por el medio ambiente, por su familia, por su país y por el mundo entero.
              </p>
              <p className="welcomemessage-text">
                Por ello, nuestro compromiso, es la formación no solo de libres pensadores, sino de líderes de opinión que promuevan la reflexión en sus ámbitos de competencia, capaces de motivar transformaciones hacia la generación de bienestar de la sociedad en toda la extensión de la palabra.
              </p>
              <p className="welcomemessage-text">
                Atrévete a formar parte de nuestra comunidad educativa y a ser testigo y ejemplo del triunfo que vivirás durante y después de los estudios que realices.
              </p>
            </div>
            <button className="welcomemessage-button" onClick={SetOpen}>Leer {isOpen ? "menos" : "más"}</button>
            <p style={{ textAlign: "center" }} className="welcomemessage-text">
              <b>¡Piensa y trabaja; lucha y vence!</b>
            </p>
            {/* <p style={{ textAlign: "center" }} className="welcomemessage-text">
              Atentamente
            </p>
            <StaticImage
              placeholder="blurred"
              alt=""
              src="../images/firma.png"
              className="welcomemessage-sign"
            /> */}
              {/* <p style={{ textAlign: "center" }} className="welcomemessage-text">
              Irma Ramírez Rivera
            </p>
            <p style={{ textAlign: "center" }} className="welcomemessage-text">
              Rectora
            </p> */}
          </div>
          <StaticImage
            placeholder="blurred"
            alt=""
            src="../images/index.png"
            className="welcomemessage-image"
          />
        </div>
      </div>
    </section >
  )
}

export default WelcomeMessage
